<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Salary advance request</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-layout wrap justify-center>
            <v-flex xs12 sm6 text-left>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Name/Designation :</span
              >
              <span class="text--primary">{{
                nameOrDesgination || "N/A"
              }}</span>
            </v-flex>
            <v-flex xs12 sm6 text-left pt-2 pt-sm-0>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Project/Division :</span
              >
              <span class="text--primary">{{
                projectOrDivision || "N/A"
              }}</span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-2>
            <v-flex xs12 sm6 text-left>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Amount :</span
              >
              <span class="text--primary"
                >₹{{ amount || "0" }} ({{ amountInWords || "N/A" }})</span
              >
            </v-flex>
            <v-flex xs12 sm6 text-left pt-2 pt-sm-0>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Advance Required By :</span
              >
              <span class="text--primary">{{ advanceDate || "N/A" }}</span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-2>
            <v-flex xs12 sm6 text-left>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Financial Year :</span
              >
              <span class="text--primary">{{ financialYear || "N/A" }}</span>
            </v-flex>
            <v-flex xs12 sm6 text-left pt-2 pt-sm-0>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Payment Type :</span
              >
              <span class="text--primary">{{ type || "N/A" }}</span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-2>
            <v-flex xs12 sm6 text-left>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Deductions From :</span
              >
              <span class="text--primary">{{
                deductionsForAdjustmentFrom || "N/A"
              }}</span>
            </v-flex>
            <v-flex xs12 sm6 text-left pt-2 pt-sm-0>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Number of Installments :</span
              >
              <span class="text--primary">{{
                numberOfInstallments || "N/A"
              }}</span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-2 v-if="appstatus === 'Send to HR'">
            <v-flex xs12 text-left>
              <span class="text-subtitle-2 font-weight-bold advsalhead"
                >Remarks By Administrative Reporting Manager :</span
              >
              <span class="text--primary">{{ recommendByHead || "N/A" }}</span>
            </v-flex>
          </v-layout>
          <v-row class="mt-4" v-if="appstatus !== 'Send to HR'">
            <v-col cols="12" class="text-center">
              <v-btn
                color="#005f32"
                dark
                rounded
                elevation="2"
                @click="remarksDialog = true"
              >
                <v-icon left>mdi-send</v-icon>
                Send To HR
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-4" v-if="appstatus === 'Send to HR'">
            <v-col cols="12" sm="6" class="text-center">
              <v-btn
                color="success"
                dark
                rounded
                elevation="2"
                @click="approveDialog = true"
              >
                <v-icon left>mdi-check-decagram-outline</v-icon>
                Approve
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn color="red" dark rounded elevation="2" @click="rejectDialog = true">
                <v-icon left>mdi-close-circle-outline</v-icon>
                Reject
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="remarksDialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            align-self-center
            pa-4
            style="background: #005f32"
            text-left
          >
            <span class="kumbhBold" style="color: #ffffff">Add Remarks</span>
          </v-flex>
        </v-layout>
        <v-layout wrap pa-6>
          <v-flex xs12 align-self-end pa-3>
            <v-textarea
              v-model="remarks"
              label="Enter your remarks"
              outlined
              :rules="[rules.required]"
              required
              rows="4"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" tile dark @click="remarksDialog = false"
            >Cancel</v-btn
          >
          &nbsp;
          <v-btn color="#005f32" outlined tile @click="submitWithRemarks"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="approveDialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            align-self-center
            pa-4
            style="background: #005f32"
            text-left
          >
            <span class="kumbhBold" style="color: #ffffff"
              >Approve Application</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap pa-6>
          <v-flex xs12 align-self-end pa-3>
            <v-text-field
              v-model="approveAmount"
              label="Approved amount"
              outlined
              :rules="[rules.required]"
              required
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs12 align-self-end pa-3>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="approvedadvanceDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="approvedadvanceDate"
                  label="Date of releasing advance"
                  outlined
                  hide-details
                  readonly
                  dense
                  class="rounded-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="approvedadvanceDate"
                no-title
                scrollable
                @change="$refs.menu.save(approvedadvanceDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 align-self-end pa-3>
            <v-textarea
              v-model="approveRemarks"
              label="Remarks"
              outlined
              :rules="[rules.required]"
              required
              rows="4"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" tile dark @click="approveDialog = false"
            >Cancel</v-btn
          >
          &nbsp;
          <v-btn color="#005f32" outlined tile @click="submitApproval"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="rejectDialog" max-width="600px">
  <v-card>
    <v-layout wrap>
      <v-flex xs12 align-self-center pa-4 style="background: #dc3545" text-left>
        <span class="kumbhBold" style="color: #ffffff">Reject Application</span>
      </v-flex>
    </v-layout>
    <v-layout wrap pa-6>
      <v-flex xs12 align-self-end pa-3>
        <v-textarea
          v-model="rejectRemarks"
          label="Remarks"
          outlined
          :rules="[rules.required]"
          required
          rows="4"
        ></v-textarea>
      </v-flex>
    </v-layout>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" tile dark @click="rejectDialog = false">Cancel</v-btn>
      &nbsp;
      <v-btn color="#005f32" outlined tile @click="submitReject">Submit</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      pageCount: 1,
      appLoading: false,
      //add
      projectOrDivision: null,
      nameOrDesgination: null,
      amountInWords: null,
      financialYear: null,
      amount: null,
      advanceDate: null,
      type: null,
      numberOfInstallments: null,
      deductionsForAdjustmentFrom: null,
      addcat2: false,
      advanceData: {},
      rules: {
        required: (value) => !!value || "Required.",
      },
      menu: false,
      remarksDialog: false,
      remarks: "",
      appstatus: "",
      recommendByHead: "",
      approveDialog: false,
      approveRemarks: "",
      approveAmount: "",
      approvedadvanceDate: "",
      rejectDialog: false,
      rejectRemarks: "",
    };
  },
  mounted() {
    this.getData();
    // this.financialYear = this.currentFinancialYear;
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    userId() {
      return this.$store.state.userData._id;
    },
    // currentFinancialYear() {
    //   const today = new Date();
    //   const month = today.getMonth();
    //   const year = today.getFullYear();

    //   if (month >= 3) {
    //     // After March
    //     return `${year}-${year + 1}`;
    //   } else {
    //     // January to March
    //     return `${year - 1}-${year}`;
    //   }
    // },
  },
  watch: {
    amount: {
      handler(newValue) {
        if (newValue) {
          const numAmount = parseInt(newValue);
          if (!isNaN(numAmount)) {
            this.amountInWords = this.numberToWords(numAmount);
          }
        } else {
          this.amountInWords = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    close(item, i) {
      this.documentFile1.splice(i, 1);
    },
    submitReject() {
    if (!this.rejectRemarks) {
      this.msg = "Please enter remarks";
      this.showsnackbar = true;
      return;
    }

    this.appLoading = true;
    axios({
      url: "/salary/advance/application/admin/reject",
      method: "POST",
      data: {
        id: this.$route.query.id,
        remarkByHR: this.rejectRemarks
      },
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        this.appLoading = false;
        if (response.data.status) {
          this.msg = response.data.msg;
          this.showsnackbar = true;
          this.rejectDialog = false;
          this.rejectRemarks = "";
          this.$router.push("/SalaryAdvanceRequest");
        } else {
          this.msg = response.data.msg;
          this.showsnackbar = true;
        }
      })
      .catch((err) => {
        this.appLoading = false;
        this.msg = "Error occurred while processing request";
        this.showsnackbar = true;
        console.log(err);
      });
  },
    submitWithRemarks() {
      if (!this.remarks) {
        this.msg = "Please enter remarks";
        this.showsnackbar = true;
        return;
      }

      this.remarksDialog = false;
      this.applySalary();
    },
    validateInput() {
      if (!this.projectOrDivision) {
        this.msg = "Please provide project Or Division";
        this.showsnackbar = true;
        return;
      } else if (!this.amount) {
        this.msg = "Please provide amount";
        this.showsnackbar = true;
        return;
      } else if (!this.advanceDate) {
        this.msg = "Please provide advance date";
        this.showsnackbar = true;
        return;
      } else if (!this.type) {
        this.msg = "Please choose type";
        this.showsnackbar = true;
        return;
      } else if (!this.numberOfInstallments) {
        this.msg = "Please provide number of installments";
        this.showsnackbar = true;
        return;
      } else if (!this.deductionsForAdjustmentFrom) {
        this.msg = "Please provide deductions for adjustment from";
        this.showsnackbar = true;
        return;
      } else {
        this.applySalary();
      }
    },
    browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/salaryadvance/application/view",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            const advanceData = response.data.data;
            // Populate form fields with existing data
            this.projectOrDivision = advanceData.projectOrDivision;
            this.appstatus = advanceData.status;
            this.nameOrDesgination = advanceData.nameOrDesgination;
            this.amount = advanceData.amount;
            this.advanceDate = this.formatDateForInput(advanceData.advanceDate);
            this.type = advanceData.type;
            this.numberOfInstallments = advanceData.numberOfInstallments;
            this.deductionsForAdjustmentFrom =
              advanceData.deductionsForAdjustmentFrom;
            this.financialYear = advanceData.fyear;
            this.recommendByHead = advanceData.recommendByHead;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDateForInput(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
    },
    applySalary() {
      axios({
        url: "/salaryadvance/application/AdmRm/remark/add",
        method: "POST",
        data: {
          id: this.$route.query.id,
          recommend: this.remarks,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.push("/SalaryAdvanceRequest");
            // this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    numberToWords(num) {
      const ones = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      const teens = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];

      const convertLessThanThousand = (n) => {
        if (n === 0) return "";

        let result = "";
        if (n >= 100) {
          result += ones[Math.floor(n / 100)] + " Hundred ";
          n %= 100;
        }

        if (n >= 20) {
          result += tens[Math.floor(n / 10)] + " ";
          n %= 10;
        } else if (n >= 10) {
          result += teens[n - 10] + " ";
          return result;
        }

        if (n > 0) {
          result += ones[n] + " ";
        }

        return result;
      };

      if (num === 0) return "Zero";

      let result = "";
      if (num >= 100000) {
        result += convertLessThanThousand(Math.floor(num / 100000)) + "Lakh ";
        num %= 100000;
      }

      if (num >= 1000) {
        result += convertLessThanThousand(Math.floor(num / 1000)) + "Thousand ";
        num %= 1000;
      }

      result += convertLessThanThousand(num);

      return result.trim() + " Rupees Only";
    },
    submitApproval() {
      if (!this.approveAmount) {
        this.msg = "Please enter approved amount";
        this.showsnackbar = true;
        return;
      }
      if (!this.approvedadvanceDate) {
        this.msg = "Please enter date of releasing advance";
        this.showsnackbar = true;
        return;
      }
      if (!this.approveRemarks) {
        this.msg = "Please enter remarks";
        this.showsnackbar = true;
        return;
      }

      this.appLoading = true;
      axios({
        url: "/salaryadvance/application/admin/approve",
        method: "POST",
        data: {
          id: this.$route.query.id,
          remarkByHR: this.approveRemarks,
          dateOfReleasingAdvance: this.approvedadvanceDate,
          amountReleased: this.approveAmount,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.approveDialog = false;
            this.approveRemarks = "";
            this.approveAmount = "";
            this.approvedadvanceDate = "";
            this.$router.push("/SalaryAdvanceRequest");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Error occurred while processing request";
          this.showsnackbar = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.primary {
  background-color: #005f32 !important;
}

.text--primary {
  font-size: 15px !important;
  /* padding: 8px 0; */
  text-transform: capitalize;
}

.v-list-item {
  padding: 16px !important;
}
.advsalhead {
  font-size: 16px !important;
}
</style>
    